<template>
  <div class="view-login">
    <div class="login-header"></div>
    <div class="login-main">
      <div class="main-header-tips flex-s">
        <div class="icon">
          <div class="iconfont icon-tuite1"></div>
        </div>
        <div class="text">
          Your account was created successfully and a password has been sent to
          your email address.
        </div>
      </div>
      <div class="login-reg-box">
        <div class="login-box border-box">
          <div class="form-box-title">Login</div>
          <div class="form-box">
            <div class="form-item">
              <div class="form-item-label">
                Email Address<span class="red-icon">*</span>
              </div>
              <div class="form-item-input">
                <el-input
                  class="clear-style"
                  v-model="loginFromData.account"
                  placeholder="email"></el-input>
              </div>
            </div>
            <div class="form-item">
              <div class="form-item-label">
                Password<span class="red-icon">*</span>
              </div>
              <div class="form-item-input">
                <el-input
                  class="clear-style"
                  type="password"
                  v-model="loginFromData.password"
                  placeholder="password"></el-input>
              </div>
            </div>
            <div class="bottom-tip flex-s point">
              <div @click="remChecked = !remChecked" class="img">
                <div v-show="remChecked" class="in-img"></div>
              </div>
              <div @click="remChecked = !remChecked" class="remember">
                Remember Me
              </div>
            </div>
            <div class="rsg-btn flex-c point" @click="userLoginOnClick">
              Log In
            </div>
            <div @click="forgotOnClick" class="forgot point">
              Lost your password?
            </div>
          </div>
        </div>
        <div class="login-box reg-box border-box">
          <div class="form-box-title">Register</div>
          <div class="form-box">
            <div class="form-item">
              <div class="form-item-label">
                Username<span class="red-icon">*</span>
              </div>
              <div class="form-item-input">
                <el-input
                  class="clear-style"
                  v-model="rsgFormData.username"
                  placeholder="Username"></el-input>
              </div>
            </div>
            <div class="form-item">
              <div class="form-item-label">
                Email<span class="red-icon">*</span>
              </div>
              <div class="form-item-input flex-e">
                <el-input
                  class="clear-style"
                  v-model="rsgFormData.email"
                  placeholder="Email"></el-input>
                <div @click="sendEmailCode" class="get-code-btn flex-c point">
                  {{ countdownTime > 0 ? `${countdownTime}S` : "Get Code " }}
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="form-item-label">
                Email verification code<span class="red-icon">*</span>
              </div>
              <div class="form-item-input">
                <el-input
                  class="clear-style"
                  v-model="rsgFormData.captcha"
                  placeholder="Email verification code"></el-input>
              </div>
            </div>
            <div class="form-item">
              <div class="form-item-label">
                password<span class="red-icon">*</span>
              </div>
              <div class="form-item-input">
                <el-input
                  class="clear-style"
                  type="password"
                  v-model="rsgFormData.password"
                  placeholder="password"></el-input>
              </div>
            </div>
            <div class="form-item">
              <div class="form-item-label">
                secondPassword<span class="red-icon">*</span>
              </div>
              <div class="form-item-input">
                <el-input
                  class="clear-style"
                  type="password"
                  v-model="rsgFormData.secondPassword"
                  placeholder="secondPassword"></el-input>
              </div>
            </div>
            <div class="form-item">
              <div class="form-item-label">
                authCode
                <!-- <span class="red-icon">*</span> -->
              </div>
              <div class="form-item-input">
                <el-input
                  class="clear-style"
                  v-model="rsgFormData.authCode"
                  placeholder="authCode"></el-input>
              </div>
            </div>
            <div class="rsg-btn flex-c point" @click="userRegOnClick">
              Register
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import {
  userRsgApi,
  sendEmailCodeApi,
  checkEmailCodeApi,
  userLoginApi,
  getNewdataApi,
  infoSubmitApi,
} from "@/network/api";
import { validateEmail } from "@/utils/common.js";
import { throttle } from "lodash";
import { encode, decode } from "js-base64";
export default {
  name: "Login",
  data() {
    return {
      remChecked: false,
      countdownTime: 0,
      rsgFormData: {
        username: "",
        password: "",
        secondPassword: "",
        email: "",
        authCode: "",
        captcha: "",
      },
      loginFromData: {
        account: "",
        password: "",
      },
      submitTimer: true,
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations([
      "setUserInfo",
      "setLanguage",
      "setToken",
      "setClickFuckInfoShow",
    ]),
    ...mapActions(["getCartList", "setTokenSync"]),
    init() {
      const remUser = localStorage.getItem("rsmNo");
      if (remUser) {
        const parseRemUserArray = remUser.split("&");
        if (parseRemUserArray.length > 1) {
          this.loginFromData.account = decode(parseRemUserArray[0]);
          this.loginFromData.password = decode(parseRemUserArray[1]);
          this.remChecked = true;
        }
      }
    },
    forgotOnClick: throttle(function () {
      this.$message.warning("Please contact customer service");
    }, 3000),
    async userLoginOnClick() {
      let flag = true;
      Object.keys(this.loginFromData).forEach((key) => {
        if (!this.loginFromData[key]) {
          if (flag) {
            this.$message.error("Please fill in the complete information");
          }
          flag = false;
        }
      });
      if (!flag) {
        return false;
      }

      const res = await userLoginApi(this.loginFromData);
      if (this.remChecked) {
        const user = encode(`${this.loginFromData.account}`);
        const pass = encode(`${this.loginFromData.password}`);
        localStorage.setItem("rsmNo", `${user}&${pass}`);
      } else {
        localStorage.setItem("rsmNo", "");
      }
      await this.setTokenSync(res?.data?.userinfo?.token);
      this.$message.success(res.msg);
      this.$router.push("/home");
    },
    async userRegOnClick() {
      if (!this.submitTimer) {
        return false;
      }
      this.submitTimer = false;
      setTimeout(() => {
        this.submitTimer = true;
      }, 3000);
      let flag = true;
      Object.keys(this.rsgFormData).forEach((key) => {
        if (key !== "authCode") {
          if (!this.rsgFormData[key]) {
            if (flag) {
              this.$message.error("Please fill in the complete information");
            }
            flag = false;
          }
        }
      });
      if (!flag) {
        return false;
      }
      if (this.rsgFormData.password !== this.rsgFormData.secondPassword) {
        this.$message.error("The two password entries are inconsistent");
        return false;
      }
      const isCheck = await this.checkEmailCode();
      if (!isCheck) {
        return false;
      }
      const params = this.rsgFormData;
      const res = await userRsgApi(params);
      await this.setTokenSync(res?.data?.userinfo?.token);
      this.$message.success(res.msg);
      // this.rsgOverlayShow = false;
      this.$router.push("/home");
    },
    async sendEmailCode() {
      if (this.countdownTime) {
        return false;
      }
      const email = this.rsgFormData.email;
      if (!validateEmail(email)) {
        this.$message.error("Email is not valid");
        return false;
      }
      const params = {
        email,
        event: "register",
      };
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      const res = await sendEmailCodeApi(params);
      console.log(res);
      this.countdownTime = 60;
      this.Timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime--;
        }
      }, 1000);
      this.$message.success(res.msg);
    },
    async checkEmailCode() {
      const params = {
        email: this.rsgFormData.email,
        event: "register",
        captcha: this.rsgFormData.captcha,
      };
      const res = await checkEmailCodeApi(params);
      return true;
    },
  },
  computed: {
    ...mapState([
      "isMinDevice",
      "homeGoods",
      "onlineUrl",
      "carList",
      "clickFuckInfoShow",
      "showTipPopup",
    ]),
    ...mapGetters(["isVip", "isLogin", "userInfo"]),
  },
};
</script>

<style lang="scss">
.view-login {
  .login-header {
    width: 100%;
    height: 1rem;
    background: skyblue;
    margin-bottom: 1rem;
  }
  .login-main {
    padding: 0 3rem;
    margin-bottom: 3rem;
    .main-header-tips {
      padding: 0.2rem 0.1rem;
      background: #f8f8f8;
      color: #797979;
      position: relative;
      margin-bottom: 1rem;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 0.03rem;
        background: #ffb100;
        top: 0rem;
        left: 0rem;
      }
    }
    .login-reg-box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .login-box {
        width: 46%;
        flex-shrink: 0;
        .form-box-title {
          font-size: 0.24rem;
          margin-bottom: 0.3rem;
        }
        .form-box {
          border: 1px solid #eaeaea;
          padding: 0.24rem;
          border-radius: 4px;
          width: 100%;
          flex-shrink: 0;
          .form-item {
            margin-bottom: 0.14rem;
            .form-item-label {
              margin-bottom: 0.08rem;
              opacity: 0.6;
              font-size: 0.2rem;
            }
            .form-item-input {
              border: 1px solid #e1e1e1;
              border-radius: 4px;
              .get-code-btn {
                width: 2rem;
                height: 41px;
                background: var(--yellow);
                color: #fff;
              }
            }
          }

          .bottom-tip {
            margin-bottom: 0.28rem;

            .img {
              width: 0.24rem;
              height: 0.24rem;
              border: 1px solid #e5e5e5;
              margin-right: 0.13rem;
              padding: 0.02rem;
              box-sizing: border-box;

              // @include pcurl("pc-home-icon11.png");
              .in-img {
                width: 100%;
                height: 100%;
                background: rgba(136, 136, 136, 1);
              }
            }

            .remember {
              font-size: 0.2rem;
              color: #888;
            }

            .forgot {
              font-size: 0.22rem;
              margin-left: auto;
            }
          }
          .rsg-btn {
            width: 1rem;
            padding: 0.1rem 0.2rem;
            background: #ffb100;
            border-radius: 0.04rem;
            color: #fff;
            margin-bottom: 0.24rem;
          }
        }
      }
      // .reg-box {
      //   width: 46%;
      //   .form-box-title {
      //     font-size: 0.24rem;
      //     margin-bottom: 0.3rem;
      //   }
      //   .form-box {
      //     padding: 0.24rem;
      //     border: 1px solid #eaeaea;
      //     border-radius: 4px;
      //     width: 100%;
      //     flex-shrink: 0;
      //     .form-item-input {
      //       border: 1px solid #e1e1e1;
      //       border-radius: 4px;
      //     }

      //     .bottom-tip {
      //       margin-bottom: 0.28rem;

      //       .img {
      //         width: 0.24rem;
      //         height: 0.24rem;
      //         border: 1px solid #e5e5e5;
      //         margin-right: 0.13rem;
      //         padding: 0.02rem;
      //         box-sizing: border-box;

      //         // @include pcurl("pc-home-icon11.png");
      //         .in-img {
      //           width: 100%;
      //           height: 100%;
      //           background: rgba(136, 136, 136, 1);
      //         }
      //       }

      //       .remember {
      //         font-size: 0.2rem;
      //         color: #888;
      //       }

      //       .forgot {
      //         font-size: 0.22rem;
      //         margin-left: auto;
      //       }
      //     }
      //     .rsg-btn {
      //       width: 1rem;
      //       padding: 0.1rem 0.2rem;
      //       background: #ffb100;
      //       border-radius: 0.04rem;
      //       color: #fff;
      //     }
      //   }
      // }
    }
  }
}
</style>
